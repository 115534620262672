import React, { useState, useEffect, useRef } from "react";
import {
    Typography,
    Box,
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useSelector, useDispatch } from "react-redux";
import RelatedVideos from "../RelatedVideos/RelatedVideos";
import PlayerLobbyView from "../PlayerLobbyView/PlayerLobbyView";
import {
    addMode,
    addCategory,
    addTitle,
    addTriviaImage,
} from "../../reducers/SettingsReducer";
import { addGameType } from "../../reducers/GameReducer";
import { IS_HOST, getLabel } from "../../helpers/Global";
import { titleCase } from "../../helpers/Global";
import {
    GAME_MODE,
    GAME_TYPE,
    SOCKET_SEND_EVENTS,
} from "../../helpers/constant";

const Settings = ({ contentContainerHeight, uiLabels }) => {
    const expandButtonContainer = useRef();
    const actionContainer = useRef();

    const theme = useTheme();
    const dispatch = useDispatch();

    let socketFromStore = useSelector(({ socket }) => socket.socket);
    let gameType = useSelector(({ game }) => game.gameType);
    let currentMode = useSelector(({ settings }) => settings.currentMode);
    let currentTitle = useSelector(({ settings }) => settings.currentTitle);
    let roomId = useSelector(({ game }) => game.roomId);
    const currentCategory = useSelector(
        ({ settings }) => settings.currentCategory
    );

    const isHost = IS_HOST(
        useSelector(({ game }) => game.gameHost),
        useSelector(({ player }) => player.playerId)
    );

    const [sprintContainerHeight, setSprintContainerHeight] = useState("auto");

    useEffect(() => {
        const buttonsContainerHeight = isHost
            ? expandButtonContainer.current.offsetHeight
            : 0;
        const newHeight = `${
            contentContainerHeight -
            (buttonsContainerHeight + actionContainer.current.offsetHeight + 6)
        }`;

        setSprintContainerHeight(newHeight);
    }, [expandButtonContainer, contentContainerHeight, currentTitle]);

    const handleExpandableClick = (e) => {
        dispatch(addMode(e.currentTarget.value));

        if (
            [GAME_MODE.MARATHON, GAME_MODE.TRAINING].includes(
                e.currentTarget.value
            ) &&
            currentCategory !== null
        ) {
            dispatch(addGameType(GAME_TYPE.CATEGORY));
            let cat = currentCategory === "All" ? "Categories" : "Category";
            const newTitle = `${currentCategory} ${cat}`;

            //update game settings
            const newConfig = {
                gameType: GAME_TYPE.CATEGORY,
                gameMode: e.currentTarget.value,
                triviaTitle: titleCase(newTitle),
                triviaImage:
                    currentCategory === "All"
                        ? `${process.env.REACT_APP_WM_URL}/uploads/share-image/placeholder/placeholder-trivia.png`
                        : currentCategory !== undefined &&
                          `${
                              process.env.REACT_APP_WM_URL
                          }/2021/img/lobby-room/${currentCategory
                              .toString()
                              .toLowerCase()
                              .replace(" ", "-")}-app.png`,
            };

            socketFromStore.emit(
                SOCKET_SEND_EVENTS.UPDATE_SETTINGS,
                roomId,
                newConfig
            );
        }
    };

    useEffect(() => {
        // only set default if currentMode is null
        if (currentMode == null) {
            let defaultMode =
                gameType === GAME_TYPE.CATEGORY
                    ? GAME_MODE.TRAINING
                    : GAME_MODE.SPRINT;

            // update default mode on first load
            dispatch(addMode(defaultMode));
        }
    }, [dispatch, gameType, currentMode]);

    return (
        <Box
            sx={{
                height: { xs: "auto", md: `100%` },
            }}
        >
            <Box
                ref={actionContainer}
                sx={{
                    flexDirection: "row",
                    width: "100%",
                    pl: { xs: theme.pLeft.main, md: theme.px16.main },
                    pr: { xs: theme.pRight.main, md: theme.px16.main },
                    pt: { xs: theme.px10.main, md: theme.px10.main },
                    pb: theme.px8.main,
                }}
            >
                <Typography
                    variant="body1"
                    sx={{
                        textAlign: { xs: "center", md: "center" },
                        textTransform: "uppercase",
                        fontWeight: "medium",
                        fontSize: { xs: 12, md: 14 },
                        pb: theme.px8.main,
                        lineHeight: "1",
                    }}
                >
                    {getLabel(
                        uiLabels,
                        "labelQuestionsAbout",
                        "Questions About"
                    )}
                    :
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        textAlign: { xs: "center", md: "center" },
                        fontWeight: "bold",
                        fontSize: { xs: 16, md: 20 },
                        textTransform: "capitalize",
                        lineHeight: "1.1",
                    }}
                >
                    {currentTitle}
                </Typography>
            </Box>
            <PlayerLobbyView containerHeight={sprintContainerHeight} />
            {isHost && (
                <Accordion
                    sx={{
                        backgroundColor: "transparent",
                        border: {
                            sm: `3px solid ${theme.palette.wmColor.main}`,
                        },
                        borderBottom: {
                            xs: `2px solid ${theme.palette.wmColor.main}`,
                            sm: `3px solid ${theme.palette.wmColor.main}`,
                        },
                        borderRadius: {
                            xs: `0px!important`,
                            sm: `${theme.borderRadius.primary}!important`,
                        },
                        margin: "0!important",
                    }}
                >
                    <AccordionSummary
                        expandIcon={
                            <ArrowDropDownIcon
                                sx={{
                                    fontSize: {
                                        xs: "2rem",
                                        sm: "4rem",
                                    },
                                    color: theme.palette.wmColor.main,
                                }}
                            />
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{
                            margin: "0!important",
                        }}
                    >
                        <Typography
                            variant="h6"
                            sx={{
                                color: theme.palette.wmColor.main,
                                fontSize: "1.1rem",
                            }}
                        >
                            {getLabel(
                                uiLabels,
                                "labelAdvanceOptions",
                                "Advanced Options"
                            )}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                        sx={{
                            px: { xs: "0", sm: "16px" },
                        }}
                    >
                        <Box
                            sx={{
                                height: { xs: "auto", md: `100%` },
                            }}
                        >
                            <Box
                                ref={expandButtonContainer}
                                sx={{
                                    display: "flex",
                                    flexDirection: { xs: "row", md: "row" },
                                    mb: { xs: ".7rem", sm: `1rem` },
                                    px: { xs: "16px", sm: 0 },
                                }}
                            >
                                <ExpandableButton
                                    mRight={"13px"}
                                    mBottom={"8px"}
                                    handleExpandableClick={
                                        handleExpandableClick
                                    }
                                    value={GAME_MODE.SPRINT}
                                    translatedValue={getLabel(
                                        uiLabels,
                                        "labelSprint",
                                        GAME_MODE.SPRINT
                                    )}
                                    defaultActiveBtn={currentMode}
                                    description={getLabel(
                                        uiLabels,
                                        "labelSprintTime",
                                        "10 Questions / 6 Minutes"
                                    )}
                                    disabled={isHost ? false : true}
                                    classForGoogleTag={"tag_sprintMode"}
                                />
                                <ExpandableButton
                                    mRight={"13px"}
                                    mBottom={"8px"}
                                    value={GAME_MODE.TRAINING}
                                    translatedValue={getLabel(
                                        uiLabels,
                                        "labelTraining",
                                        GAME_MODE.TRAINING
                                    )}
                                    handleExpandableClick={
                                        handleExpandableClick
                                    }
                                    defaultActiveBtn={currentMode}
                                    description={getLabel(
                                        uiLabels,
                                        "labelTrainingTime",
                                        "20 Questions/12 Minutes"
                                    )}
                                    disabled={isHost ? false : true}
                                    classForGoogleTag={"tag_trainingMode"}
                                />
                                <ExpandableButton
                                    value={GAME_MODE.MARATHON}
                                    translatedValue={getLabel(
                                        uiLabels,
                                        "labelMarathon",
                                        GAME_MODE.MARATHON
                                    )}
                                    handleExpandableClick={
                                        handleExpandableClick
                                    }
                                    defaultActiveBtn={currentMode}
                                    description={getLabel(
                                        uiLabels,
                                        "labelMarathonTime",
                                        "50 Questions/30 Minutes"
                                    )}
                                    disabled={isHost ? false : true}
                                    classForGoogleTag={"tag_marathonMode"}
                                />
                            </Box>
                            {/* section for suggested videos + category buttons */}
                            {currentMode === GAME_MODE.SPRINT && (
                                <RelatedVideos
                                    sprintContainerHeight={
                                        sprintContainerHeight
                                    }
                                    uiLabels={uiLabels}
                                />
                            )}
                            {(currentMode === GAME_MODE.TRAINING ||
                                currentMode === GAME_MODE.MARATHON) && (
                                <CategoryButtons
                                    categoryButtonContainer={
                                        sprintContainerHeight
                                    }
                                    uiLabels={uiLabels}
                                />
                            )}
                        </Box>
                    </AccordionDetails>
                </Accordion>
            )}
        </Box>
    );
};

const ExpandableButton = ({
    mRight,
    mBottom,
    handleExpandableClick,
    value,
    translatedValue,
    defaultActiveBtn,
    description,
    disabled,
    classForGoogleTag,
    uiLabels,
}) => {
    const theme = useTheme();
    return (
        <>
            <Button
                value={value}
                sx={{
                    textTransform: "capitalize",
                    height: { xs: "auto", md: "auto" },
                    border:
                        defaultActiveBtn === value
                            ? `3px solid ${theme.palette.wmColor.main}`
                            : "none",
                    padding: "10px",
                    borderRadius: theme.borderRadius.primary,
                    width: { xs: "100%", md: "33.33%" },
                    marginRight: mRight,
                    marginBottom: { xs: mBottom, md: 0 },
                    backgroundColor:
                        defaultActiveBtn === value
                            ? theme.palette.gameModeActive.main
                            : theme.palette.backgroundColor.main,
                    ":hover": {
                        backgroundColor:
                            defaultActiveBtn === value
                                ? theme.palette.gameModeActive.main
                                : theme.palette.backgroundColor.main,
                    },
                    ":disabled": {
                        color: "#fff",
                        backgroundColor:
                            defaultActiveBtn === value
                                ? theme.palette.gameModeActive.main
                                : theme.palette.backgroundColor.main,
                    },
                    boxShadow: "none",
                }}
                variant="contained"
                onClick={
                    !disabled
                        ? (e) => handleExpandableClick(e)
                        : () => {
                              return;
                          }
                }
                disabled={disabled}
                className={classForGoogleTag}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                    className={classForGoogleTag}
                >
                    {value === GAME_MODE.SPRINT ? (
                        <DirectionsWalkIcon
                            sx={{
                                display: { xs: "none", md: "block" },
                                mx: `${theme.px8.main}`,
                            }}
                        />
                    ) : value === GAME_MODE.TRAINING ? (
                        <>
                            <Box
                                sx={{
                                    display: { xs: "none", md: "block" },
                                }}
                            >
                                <Box>
                                    <Typography
                                        sx={{
                                            height: "2px",
                                            backgroundColor: "#fff",
                                            width: "8px",
                                        }}
                                    ></Typography>
                                    <Typography
                                        sx={{
                                            mt: "2px",
                                            ml: "-2px",
                                            height: "2px",
                                            backgroundColor: "#fff",
                                            width: "8px",
                                        }}
                                    ></Typography>
                                </Box>
                            </Box>
                            <DirectionsRunIcon
                                sx={{
                                    display: { xs: "none", md: "block" },
                                    mr: `${theme.px8.main}`,
                                }}
                            />
                        </>
                    ) : (
                        <>
                            <Box
                                sx={{
                                    display: { xs: "none", md: "block" },
                                }}
                            >
                                <Box>
                                    <Typography
                                        sx={{
                                            height: "2px",
                                            backgroundColor: "#fff",
                                            width: "8px",
                                        }}
                                    ></Typography>
                                    <Typography
                                        sx={{
                                            mt: "2px",
                                            ml: "-2px",
                                            height: "2px",
                                            backgroundColor: "#fff",
                                            width: "8px",
                                        }}
                                    ></Typography>

                                    <Typography
                                        sx={{
                                            mt: "2px",
                                            ml: "-4px",
                                            height: "2px",
                                            backgroundColor: "#fff",
                                            width: "8px",
                                        }}
                                    ></Typography>
                                </Box>
                            </Box>
                            <DirectionsRunIcon
                                sx={{
                                    display: { xs: "none", md: "block" },
                                    mr: `${theme.px8.main}`,
                                }}
                            />
                        </>
                    )}
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Typography
                            textAlign={"left"}
                            sx={{ fontWeight: "bold", lineHeight: "1.1" }}
                        >
                            {translatedValue}
                        </Typography>
                        <Typography
                            textAlign={"left"}
                            variant="subtitle2"
                            sx={{ display: { xs: "none", md: "block" } }}
                        >
                            {description}
                        </Typography>
                        <Typography
                            textAlign={"left"}
                            variant="subtitle2"
                            sx={{
                                display: {
                                    xs: "block",
                                    md: "none",
                                },
                                fontSize: "0.8rem",
                            }}
                        >
                            {description.split("/")[0]}
                        </Typography>
                    </Box>
                </Box>
            </Button>
        </>
    );
};

const CategoryButtons = ({ categoryButtonContainer, uiLabels }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    let currentMode = useSelector(({ settings }) => settings.currentMode);
    let roomId = useSelector(({ game }) => game.roomId);
    let socketFromStore = useSelector(({ socket }) => socket.socket);

    let heightforButtons = (categoryButtonContainer - 58) / 4;

    let differentCategories = [
        {
            type: "film",
            color: "#DCC400",
            width: "100%",
            height: { xs: "10vh", md: "8rem" },
            id: "trivia_select_film",
            label: getLabel(uiLabels, "film", "Film"),
        },
        {
            type: "TV",
            color: "#C16F4A",
            width: "100%",
            height: { xs: "10vh", md: "8rem" },
            id: "trivia_select_tv",
            label: getLabel(uiLabels, "tv", "TV"),
        },
        {
            type: "music",
            color: "#e083d3",
            width: "100%",
            height: { xs: "10vh", md: "8rem" },
            id: "trivia_select_music",
            label: getLabel(uiLabels, "music", "Music"),
        },
        {
            type: "celeb",
            color: "#9A45AA",
            width: "100%",
            height: { xs: "10vh", md: "8rem" },
            id: "trivia_select_celeb",
            label: getLabel(uiLabels, "celeb", "Celeb"),
        },
        {
            type: "video games",
            color: "#528FE0",
            width: "100%",
            height: { xs: "10vh", md: "8rem" },
            id: "trivia_select_video_games",
            label: getLabel(uiLabels, "video-games", "Video Games"),
        },
        {
            type: "comic",
            color: "#D35B67",
            width: "100%",
            height: { xs: "10vh", md: "8rem" },
            id: "trivia_select_comic",
            label: getLabel(uiLabels, "comic", "Comic"),
        },
        {
            type: "anime",
            color: "#D8AA6E",
            width: "100%",
            height: { xs: "10vh", md: "8rem" },
            id: "trivia_select_anime",
            label: getLabel(uiLabels, "anime", "Anime"),
        },
        {
            type: "pop culture",
            color: "#8071D0",
            width: "100%",
            height: { xs: "10vh", md: "8rem" },
            id: "trivia_select_pop_culture",
            label: getLabel(uiLabels, "pop-culture", "Pop Culture"),
        },
        {
            type: "exclusive",
            color: "#4DB2A3",
            width: "100%",
            height: { xs: "10vh", md: "8rem" },
            id: "trivia_select_exclusive",
            label: getLabel(uiLabels, "exclusive", "Exclusive"),
        },
    ];

    let allCategory = {
        type: "All",
        color: "#4098C7",
        width: "100%",
        height: { xs: "10vh", md: "6rem" },
    };

    const handleCategoryClick = (type, label) => {
        let cat = type === "All" ? "Categories" : "Category";
        dispatch(addCategory(type));
        const newTitle = label ?? `${type} ${cat}`;
        dispatch(addTitle(newTitle));
        dispatch(addGameType(GAME_TYPE.CATEGORY));

        const triviaImage = `${
            process.env.REACT_APP_WM_URL
        }/2021/img/lobby-room/${type
            .toString()
            .toLowerCase()
            .replace(" ", "-")}-app.png`;
        dispatch(addTriviaImage(triviaImage));

        const newConfig = {
            gameType: GAME_TYPE.CATEGORY,
            gameMode: currentMode,
            category: type,
            triviaTitle: titleCase(newTitle),
            triviaImage: triviaImage,
        };

        socketFromStore.emit(
            SOCKET_SEND_EVENTS.UPDATE_SETTINGS,
            roomId,
            newConfig
        );
    };

    let currentCategory = useSelector(
        ({ settings }) => settings.currentCategory
    );

    return (
        <>
            <Box
                sx={{
                    display: "grid",
                    gridTemplateColumns: {
                        md: "repeat(3, 1fr)",
                        xs: "repeat(3, 1fr)",
                    },
                    gridGap: theme.px8.main,
                    pt: { xs: "0", md: theme.px16.main },
                    px: theme.categoryButtons.px,
                }}
            >
                {differentCategories.map((category) => (
                    <Button
                        key={category.type}
                        sx={{
                            opacity:
                                currentCategory &&
                                currentCategory.toLowerCase() ===
                                    category.type.toLowerCase()
                                    ? 1
                                    : 0.4,
                            border:
                                currentCategory &&
                                currentCategory.toLowerCase() ===
                                    category.type.toLowerCase()
                                    ? `4px solid ${theme.palette.wmColor.main} !important`
                                    : "none",
                            borderRadius: theme.borderRadius.primary,
                            height: {
                                xs: category.height.xs,
                                md: category.height.md,
                            },
                            width: category.width,
                            cursor: "pointer",
                            backgroundColor: category.color,
                            color: "#333",
                            textTransform: "capitalize",
                            ":hover": { backgroundColor: category.color },
                        }}
                        onClick={() =>
                            handleCategoryClick(category.type, category.label)
                        }
                        id={category.id}
                    >
                        {category.label}
                    </Button>
                ))}
            </Box>
            <Box
                sx={{
                    pt: theme.px8.main,
                    px: theme.categoryButtons.px,
                }}
            >
                <Button
                    sx={{
                        opacity:
                            currentCategory === null ||
                            (currentCategory !== undefined &&
                                currentCategory.toLowerCase() ===
                                    allCategory.type.toLowerCase())
                                ? 1
                                : 0.4,
                        border:
                            currentCategory &&
                            currentCategory.toLowerCase() ===
                                allCategory.type.toLowerCase()
                                ? `4px solid ${theme.palette.wmColor.main} !important`
                                : "none",
                        borderRadius: theme.borderRadius.primary,
                        height: {
                            xs: allCategory.height.xs,
                            md: allCategory.height.md,
                        },
                        width: allCategory.width,
                        cursor: "pointer",
                        backgroundColor: allCategory.color,
                        color: "#333",
                        textTransform: "capitalize",
                        ":hover": { backgroundColor: allCategory.color },
                    }}
                    onClick={() => handleCategoryClick(allCategory.type)}
                >
                    {getLabel(uiLabels, "allCategories", "All Categories")}
                </Button>
            </Box>
        </>
    );
};

export default Settings;
