import { Box, Typography } from "@mui/material";
import React from "react";
import { getLabel } from "../../helpers/Global";
import styles from "./Question.module.css";

export default function Question({
    questionObj,
    theme,
    uiLabels,
    index,
    questionsCount,
    currentCategory,
}) {
    return (
        <>
            {/* content holder box */}
            <Box
                sx={{
                    width: "100%",
                    height: "100%",
                    overflow: "auto",
                    backgroundImage: {
                        xs: `linear-gradient(360deg, #181818 50%, rgba(24, 24, 24, 0.5) 100%), url(${questionObj.thumbnail})`,
                        sm: `linear-gradient(360deg, #181818 22%, rgba(24, 24, 24, 0.5) 100%), url(${questionObj.thumbnail})`,
                    },
                    backgroundSize: {
                        xs: "auto",
                        sm: "100%",
                    },
                    borderRadius: {
                        xs: "0px",
                        md: `${theme.borderRadius.primary}`,
                    },
                    backgroundRepeat: "no-repeat",
                    //filter: "brightness(0.4)",
                    position: "absolute",
                    top: 0,
                }}
            ></Box>

            {/* 1. end of image box*/}
            <Box
                className="TESTING"
                sx={{
                    position: "relative",
                    paddingTop: "1rem",
                    paddingBottom: `1rem`,
                    minHeight: { xs: "20vh", md: "20vh" },
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    border: "0px solid red",
                }}
            >
                <Typography
                    id="questionIndex"
                    variant="subtitle1"
                    sx={{
                        color: theme.palette.wmColor.main,
                        fontWeight: "bold",
                        letterSpacing: "-0.5px",
                        pl: theme.pLeft.secondary,
                        pr: theme.pRight.secondary,
                        fontSize: {
                            xs: "1rem",
                            sm: "1.4rem",
                            md: "1rem",
                        },
                    }}
                >
                    {getLabel(uiLabels, "labelQuestion", "Question")}{" "}
                    <span style={{ letterSpacing: "-2px" }}>
                        {index + 1} / {questionsCount}
                    </span>
                </Typography>
                {currentCategory &&
                    currentCategory.toLowerCase().trim() === "all" &&
                    questionObj.cat_new && (
                        <Typography
                            variant="body1"
                            sx={{
                                color: theme.palette.textColor,
                                pl: theme.pLeft.secondary,
                                pr: theme.pRight.secondary,
                                fontSize: "0.9rem",
                            }}
                        >
                            Category: {questionObj.cat_new ?? ""}
                        </Typography>
                    )}
                <Typography
                    variant="body1"
                    sx={{
                        color: theme.palette.textColor,
                        fontWeight: "500",
                        lineHeight: { xs: "1.1", md: "1" },
                        pl: theme.pLeft.secondary,
                        pr: theme.pRight.secondary,
                        textShadow: "1px 1px 1px #000",
                        letterSpacing: "-.25px",
                        fontSize: {
                            xs: "1.35rem",
                            sm: "1.8rem",
                            md: "1.8rem",
                        },
                        lineHeight: {
                            xs: "1.35rem",
                            sm: "1.8rem",
                            md: "1.8rem",
                        },
                        display: "flex",
                        flexGrow: 1,
                        alignItems: "end",
                    }}
                >
                    {questionObj.question}
                </Typography>
            </Box>
        </>
    );
}
