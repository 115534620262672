const initialState = {
    playerId: null,
    playerName: null,
    playerAvatar: null,
    inviteCode: null,
};

const PlayerReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SAVE_YOUR_PROFILE":
            return Object.assign({}, state, {
                ...state,
                playerId: action.data.playerId,
                playerName: action.data.playerName,
                playerAvatar: action.data.playerAvatar,
            });

        case "UPDATE_INVITE_CODE":
            return Object.assign({}, state, {
                ...state,
                inviteCode: action.data.inviteCode,
            });

        default:
            return state;
    }
};

//adding the player details
export const saveYourProfile = (
    playerId,
    playerName,
    playerAvatar,
    inviteCode
) => {
    return {
        type: "SAVE_YOUR_PROFILE",
        data: {
            playerId,
            playerName,
            playerAvatar,
            inviteCode: inviteCode ?? null,
        },
    };
};

//update invite code
export const updateInviteCode = (inviteCode) => {
    return {
        type: "UPDATE_INVITE_CODE",
        data: {
            inviteCode: inviteCode,
        },
    };
};

export default PlayerReducer;
