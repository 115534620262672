import { Typography, CssBaseline, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import DoneIcon from "@mui/icons-material/Done";
import { titleCase } from "../../helpers/Global";

const MultipleAnswers = ({
    questionObj,
    buttonBackgroundColor,
    questionTimer,
    correctAnswer,
    matches,
    clicked,
    selectedAns,
    handleAnswerClick,
}) => {
    const theme = useTheme();
    return (
        <>
            <CssBaseline />
            {questionObj.answers.map((answer, index) => (
                <Button
                    name={answer.id}
                    key={answer.id}
                    sx={{
                        backgroundColor:
                            questionTimer === 0 && correctAnswer == answer.id
                                ? theme.palette.correct.main
                                : buttonBackgroundColor,
                        color: `${theme.palette.textColor.main} !important`,
                        height: matches ? "50px" : "40px",
                        borderRadius: theme.borderRadius.secondary,
                        "&:hover": {
                            backgroundColor:
                                questionTimer === 0 &&
                                correctAnswer == answer.id
                                    ? theme.palette.correct.main
                                    : buttonBackgroundColor,
                            color: `${theme.palette.textColor.main} !important`,
                        },
                        opacity:
                            clicked &&
                            selectedAns != answer.id &&
                            correctAnswer != answer.id
                                ? "0.5"
                                : "1",
                        filter:
                            clicked && selectedAns == answer.id
                                ? "brightness(1.2)"
                                : "brightness(1)",
                    }}
                    onClick={() => handleAnswerClick(answer.id)}
                    disabled={clicked || questionTimer === 0}
                >
                    <Typography
                        variant="body1"
                        sx={{
                            fontWeight: "600",
                            textTransform: "capitalize",
                            lineHeight: "1",
                            fontSize: { xs: "1.2rem", md: "1.4rem" },
                            textShadow: "1px 1px 1px #000",
                            letterSpacing: "-.1px",
                        }}
                    >
                        <span
                            style={{
                                display: "inline-block",
                                verticalAlign: "middle",
                            }}
                        >
                            {["true", "false"].includes(answer.id)
                                ? titleCase(answer.answer)
                                : answer.answer}{" "}
                        </span>
                        {questionTimer === 0 && correctAnswer == answer.id ? (
                            <DoneIcon
                                sx={{
                                    fontWeight: "700",
                                    lineHeight: "normal",
                                    verticalAlign: "middle",
                                    paddingLeft: "5px",
                                    paddingBottom: "5px",
                                }}
                            />
                        ) : (
                            ""
                        )}
                    </Typography>
                </Button>
            ))}
        </>
    );
};

export default MultipleAnswers;
