import React, { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, List, Stack } from "@mui/material";
import Player from "../Player/Player";
import styles from "./Players.module.css";
import useSocketEvents from "../../hooks/useSocketEvents";
import { playerAnswered } from "../../reducers/GameReducer";
import { SOCKET_ACTIONS, SOCKET_RECEIVE_EVENTS } from "../../helpers/constant";
import { ViewportList } from "react-viewport-list";

const Players = ({ questionStart, players }) => {
    const dispatch = useDispatch();

    const gameStatus = useSelector(({ game }) => {
        return game.gameStatus;
    });

    const playersContainer = useRef();

    const handleScroll = (evt) => {
        if (!playersContainer) return;

        evt.preventDefault();
        playersContainer.current.scrollLeft += evt.deltaY;
    };

    useEffect(() => {
        if (!playersContainer || !playersContainer.current) return;

        playersContainer.current.addEventListener("wheel", handleScroll);

        return () => {
            if (!playersContainer || !playersContainer.current) return;

            playersContainer.current.removeEventListener("wheel", handleScroll);
        };
    }, []);

    return (
        <>
            <Box
                ref={playersContainer}
                className={styles.playersContainer}
                sx={{
                    position: "relative",
                    width: { xs: "100%" },
                    overflowX: "auto",
                }}
                display={"flex"}
            >
                <ViewportList
                    items={players}
                    itemMinSize={10}
                    margin={0}
                    axis="x"
                >
                    {(player, index) => {
                        if (player.spectator) return;

                        return <Player key={player.id} player={player} />;
                    }}
                </ViewportList>
            </Box>
        </>
    );
};

export default Players;

const OpenSpot = () => {
    const players = useSelector(({ game }) => {
        return game.gamePlayers;
    });

    let freeSpots = [
        { id: null, name: "open" },
        { id: null, name: "open" },
        { id: null, name: "open" },
        { id: null, name: "open" },
        { id: null, name: "open" },
        { id: null, name: "open" },
    ];

    return (
        <>
            {freeSpots.map((spot, index) => {
                return (
                    index + players.length < 6 && (
                        <Player key={index} player={spot} />
                    )
                );
            })}
        </>
    );
};
