/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Avatar,
    Badge,
    Button,
    Grid,
    IconButton,
    ListItem,
    Tooltip,
    Typography,
    Box,
} from "@mui/material";

import { AVATAR_SIZE, GAME_STATUS } from "../../helpers/constant";
import { COLORS } from "../../helpers/constant";

import playerCSS from "./Player.module.css";

// import icons
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";

import { faCrown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { SOCKET_SEND_EVENTS } from "../../helpers/constant";
import AlertDialog from "../AlertDialog/AlertDialog";
import { addAlert, removeAlert } from "../../reducers/AlertReducer";
import Ribbon from "../Ribbon/Ribbon";

export default function Player({ player, setRemovePlayer }) {
    const dispatch = useDispatch();
    const socketFromStore = useSelector(({ socket }) => socket.socket);

    const gameStatus = useSelector(({ game }) => game.gameStatus);

    const gameHost = useSelector(({ game }) => game.gameHost);

    const roomId = useSelector(({ game }) => game.roomId);

    const me = useSelector(({ player }) => player);

    let [showTooltip, setShowTooltip] = useState(false);

    function isLobbyRoom() {
        return gameStatus === GAME_STATUS.WAITING;
    }

    function hasCrown() {
        return player.isLeader && !isLobbyRoom();
    }

    function isAnswerCorrect() {
        return player.isCorrect && !isLobbyRoom();
    }

    function isPlayerMe() {
        return player.id == me.playerId;
    }

    function isPlayerHost() {
        return player.id == gameHost;
    }

    function imHost() {
        return me.playerId == gameHost;
    }

    function showKickPlayer() {
        return imHost() && !isPlayerHost() && isLobbyRoom();
    }

    function handleRemovePlayer() {
        dispatch(
            addAlert({
                title: `Confirm`,
                message: `Do you want to kick '${player.name}'?`,
                buttons: [
                    {
                        text: "No",
                        callback: () => {
                            dispatch(removeAlert());
                        },
                    },
                    {
                        text: "Yes",
                        callback: () => {
                            socketFromStore.emit(
                                SOCKET_SEND_EVENTS.LEAVE_ROOM,
                                player.id,
                                roomId,
                                player.name
                            );
                            dispatch(removeAlert());
                        },
                    },
                ],
            })
        );
    }

    const active =
        gameStatus === GAME_STATUS.WAITING
            ? playerCSS.active
            : player.didAnswer || player.correct
            ? playerCSS.active
            : playerCSS.inactive;

    function handleShowTooltip() {
        setShowTooltip(true);
    }

    function handlehideTooltip() {
        setShowTooltip(false);
    }

    return (
        <>
            <ListItem
                className={`${playerCSS.listItemDefault} ${active}`}
                sx={{
                    minWidth: { xs: "16.6%" },
                    maxWidth: { xs: "16.6%" },
                    alignItems: "end",
                    padding: {
                        xs: "2px!important",
                        sm: "10px",
                    },
                }}
            >
                <Grid
                    container
                    sx={{ width: "100%", flexDirection: "column" }}
                    alignItems="center"
                >
                    <Grid
                        item
                        xs={12}
                        align="center"
                        sx={{
                            paddingTop: {
                                xs: hasCrown() ? "8px" : 0,
                                md: "0px",
                            },
                        }}
                    >
                        <Badge
                            overlap="circular"
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            badgeContent={
                                showKickPlayer() ? (
                                    <IconButton
                                        onClick={handleRemovePlayer}
                                        color="primary"
                                        sx={{ ml: { xs: ".8rem", md: 2 } }}
                                    >
                                        {player.name !== "open" && (
                                            <CancelIcon
                                                sx={{
                                                    color: COLORS.wmTheme,
                                                    fontSize: {
                                                        xs: "24px",
                                                        sm: "28px",
                                                    },
                                                }}
                                            />
                                        )}
                                    </IconButton>
                                ) : (
                                    ""
                                )
                            }
                            sx={{
                                width: "auto",
                                mt: { md: hasCrown() ? 2 : 0 },
                                position: "relative",
                            }}
                        >
                            <Box
                                sx={{
                                    position: "absolute",
                                    top: {
                                        xs: "-1rem",
                                        sm: "-1.2rem",
                                    },
                                    left: "50%",
                                    transform: "translateX(-50%)",
                                }}
                            >
                                {isPlayerHost() &&
                                    gameStatus === GAME_STATUS.WAITING && (
                                        <Typography
                                            component="h6"
                                            noWrap
                                            align="center"
                                            sx={{
                                                textTransform: "uppercase",
                                                fontSize: {
                                                    xs: ".7rem",
                                                    sm: ".9rem",
                                                },
                                            }}
                                        >
                                            Host
                                        </Typography>
                                    )}
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <Button
                                    variant="text"
                                    onClick={handleShowTooltip}
                                    onMouseEnter={handleShowTooltip}
                                    onMouseLeave={handlehideTooltip}
                                    sx={{
                                        padding: "0px",
                                        backgroundColor:
                                            "transparent!important",
                                        minWidth: "auto",
                                    }}
                                >
                                    <Tooltip
                                        onClose={handlehideTooltip}
                                        open={showTooltip}
                                        disableFocusListener
                                        title={player.name}
                                    >
                                        <Avatar
                                            src={player.avatar}
                                            sx={{
                                                mx: "auto",
                                                padding: "5%",
                                                border:
                                                    player.name === "open"
                                                        ? {
                                                              xs: "2px dashed #fff",
                                                              sm: "2px dashed #fff",
                                                          }
                                                        : { xs: 2, md: 4 },
                                                borderColor: isPlayerMe()
                                                    ? "#00FFF1!important"
                                                    : player.name === "open"
                                                    ? "#fff!important"
                                                    : "transparent!important",
                                                opacity:
                                                    player.name === "open" ||
                                                    isAnswerCorrect()
                                                        ? "0.6"
                                                        : "1",
                                                width: {
                                                    xs: isPlayerMe()
                                                        ? "100%"
                                                        : AVATAR_SIZE.other.xs,
                                                    sm: isPlayerMe()
                                                        ? AVATAR_SIZE.me.sm
                                                        : AVATAR_SIZE.other.sm,
                                                },
                                                height: {
                                                    xs: isPlayerMe()
                                                        ? "auto"
                                                        : AVATAR_SIZE.other.xs,
                                                    sm: isPlayerMe()
                                                        ? AVATAR_SIZE.me.sm
                                                        : AVATAR_SIZE.other.sm,
                                                },
                                                backgroundColor:
                                                    player.name === "open"
                                                        ? "#8B8B8B"
                                                        : player.bgColor,
                                                "> img": {
                                                    borderRadius: "50%",
                                                },
                                            }}
                                        >
                                            {" "}
                                        </Avatar>
                                    </Tooltip>
                                </Button>
                            </Box>

                            <FontAwesomeIcon
                                icon={faCrown}
                                className={
                                    hasCrown()
                                        ? playerCSS.crown
                                        : playerCSS.noCrown
                                }
                            />

                            <CheckIcon
                                className={playerCSS.playerCorrect}
                                sx={{
                                    display: isAnswerCorrect()
                                        ? "block"
                                        : "none",
                                    fontSize: {
                                        xs: "30px",
                                        sm: "50px",
                                    },
                                    fontWeight: "600",
                                }}
                            />

                            <Ribbon
                                bonusPoints={player.bonusPoints ?? []}
                                showRibbon={player.showRibbon}
                            />
                        </Badge>
                    </Grid>

                    <Grid item xs={12} align="center">
                        {/* gameStatus = waiting, show player name
                        gameStatus = in-game, show score */}

                        <Typography
                            noWrap
                            component="h6"
                            sx={{
                                fontSize: { xs: "0.7rem", sm: "1rem" },
                                textAlign: { xs: "center" },
                                opacity: player.name === "open" && 0.6,
                            }}
                        >
                            {player.name}
                        </Typography>

                        {/* gameStatus = in-game, show score */}
                        {gameStatus === GAME_STATUS.INGAME && (
                            <Typography
                                noWrap
                                variant="body1"
                                sx={{
                                    textAlign: { xs: "center" },
                                    letterSpacing: "-0.3px",
                                    lineHeight: "1.2",
                                }}
                            >
                                {player.score}
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </ListItem>
        </>
    );
}
