const initialState = {
    gamePlayers: [], //list of current players
    gameStatus: null, //status of the game waiting/starting/in-game/end
    gameType: null,
    gameHost: null, //who is the current host
    roomId: null,

    language: null,
    languages: [],

    uiLabels: null,
};

const GameReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ADD_GAME_TYPE":
            return Object.assign({}, state, {
                gameType: action.data,
            });

        case "UPDATE_GAME_STATUS":
            return Object.assign({}, state, {
                gameStatus: action.data,
            });

        case "UPDATE_GAME_HOST":
            return Object.assign({}, state, {
                gameHost: action.data,
            });

        case "UPDATE_ROOM_ID":
            return Object.assign({}, state, {
                roomId: action.data,
            });

        case "POPULATE_PLAYERS":
            return Object.assign({}, state, {
                gamePlayers: action.data,
            });

        case "PLAYER_JOINED": {
            //only append if player is not on the list
            if (
                [...state.gamePlayers].find(
                    (player) => player.id == action.data.id
                )
            )
                return state;

            return Object.assign({}, state, {
                gamePlayers: [...state.gamePlayers, action.data],
            });
        }

        case "PLAYER_REMOVED":
            return Object.assign({}, state, {
                gamePlayers: [...state.gamePlayers].filter((player) => {
                    return player.id != action.data;
                }),
            });

        case "PLAYER_RESET": {
            const copy = [...state.gamePlayers].map((player) => {
                action.data.forEach((field) => {
                    player[field] = false;
                });
                return player;
            });

            return Object.assign({}, state, {
                gamePlayers: copy,
            });
        }

        case "PLAYER_ANSWERED": {
            const copy = [...state.gamePlayers];
            const player = copy.find((p) => p.id == action.data);
            player.didAnswer = true;

            return Object.assign({}, state, {
                gamePlayers: copy,
            });
        }

        case "UPDATE_LANGUAGES":
            return Object.assign({}, state, {
                languages: action.data,
            });

        case "UPDATE_LANGUAGE":
            return Object.assign({}, state, {
                language: action.data,
            });

        case "UPDATE_UI_LABELS":
            return Object.assign({}, state, {
                uiLabels: action.data,
            });

        default:
            return state;
    }
};

//adding game type
export const addGameType = (gameType) => {
    return {
        type: "ADD_GAME_TYPE",
        data: gameType,
    };
};

//adding game status
export const updateGameStatus = (gameStatus) => {
    return {
        type: "UPDATE_GAME_STATUS",
        data: gameStatus,
    };
};

//update game status
export const updateGameHost = (gameHost) => {
    return {
        type: "UPDATE_GAME_HOST",
        data: gameHost,
    };
};

//adding which room the player is in
export const updateRoomId = (roomId) => {
    return {
        type: "UPDATE_ROOM_ID",
        data: roomId,
    };
};

//populate players
export const populatePlayers = (players) => {
    return {
        type: "POPULATE_PLAYERS",
        data: players,
    };
};

//add player
export const playerJoined = (player) => {
    return {
        type: "PLAYER_JOINED",
        data: player,
    };
};

//remove player
export const playerRemoved = (playerId) => {
    return {
        type: "PLAYER_REMOVED",
        data: playerId,
    };
};

//player reset, method to reset player's didAnswer, isCorrect, and isLeader
export const playerReset = (
    playerFields = ["didAnswer", "isCorrect", "isLeader"]
) => {
    return {
        type: "PLAYER_RESET",
        data: playerFields,
    };
};

//player answered
export const playerAnswered = (playerId) => {
    return {
        type: "PLAYER_ANSWERED",
        data: playerId,
    };
};

//add bot
export const addBot = () => {
    return {
        type: "ADD_BOT",
    };
};

export const updateLanguages = (languages) => {
    return {
        type: "UPDATE_LANGUAGES",
        data: languages,
    };
};

export const updateLanguage = (language) => {
    return {
        type: "UPDATE_LANGUAGE",
        data: language,
    };
};

export const updateUILabels = (uiLabels) => {
    return {
        type: "UPDATE_UI_LABELS",
        data: uiLabels,
    };
};

export default GameReducer;
