/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { Box, Typography, CssBaseline, Container, Grid } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import Players from "../Players/Players";
import styles from "./Play.module.css";
import { useTheme } from "@mui/material/styles";
import Timer from "../Timer/Timer";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
    SOCKET_SEND_EVENTS,
    SOCKET_RECEIVE_EVENTS,
    GAME_STATUS,
    SOCKET_ACTIONS,
} from "../../helpers/constant";
import { useNavigate } from "react-router-dom";
import { addPlayTimer } from "../../reducers/TimerReducer";
import {
    addQuestions,
    updateMaxSeconds,
} from "../../reducers/QuestionsReducer";
import {
    updateGameStatus,
    playerReset,
    playerAnswered,
    populatePlayers,
} from "../../reducers/GameReducer";
import { updateCorrectAnswer } from "../../reducers/AnswersReducer";
import useSocketEvents from "../../hooks/useSocketEvents";
import SnackBar from "../../helpers/SnackBar";
import MultipleAnswers from "../MultipleAnswers/MultipleAnswers";
import TfAnswers from "../TfAnswers/TfAnswers";
import QuestionVote from "../QuestionVote/QuestionVote";
import ErrorPage from "../ErrorPage/ErrorPage";
import { getLabel, putSelfToStart } from "../../helpers/Global";
import Question from "../Question/Question";

const Play = ({ uiLabels }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const matches = useMediaQuery("(min-width:400px)");
    const questionBoxRef = useRef();
    const answerContainerRef = useRef();
    const openSnackbar = useSelector(({ message }) => message.openSnackbar);
    const index = useSelector(({ question }) => question.index);
    const questions = useSelector(({ question }) => question.questions);
    const questionsCount = useSelector(
        ({ question }) => question.questionsCount
    );
    const questionObj = useSelector(({ question }) => question.questionObject);
    const questionIndex = useSelector(({ question }) => question.index);
    const message = useSelector(({ message }) => message.message);
    const socketFromStore = useSelector(({ socket }) => socket.socket);
    const roomId = useSelector(({ game }) => game.roomId);
    const me = useSelector(({ player }) => player);
    const questionGrid = useRef();
    const questionTimer = useSelector(({ timer }) => timer.playTimer);
    const correctAnswer = useSelector(({ answers }) => answers.correctAnswer);
    const gameStatus = useSelector(({ game }) => game.gameStatus);
    const gameType = useSelector(({ game }) => game.gameType);
    const currentCategory = useSelector(
        ({ settings }) => settings.currentCategory
    );

    const players = useSelector(({ game }) => {
        return game.gamePlayers;
    });

    const [selectedAns, setSelectedAns] = useState(null);
    const [clicked, setClicked] = useState(false);
    const [voted, setVoted] = useState(false);
    const [vote, setVote] = useState(null);
    const [buttonBackgroundColor, setButtonBackgroundColor] = useState(
        theme.palette.wmColor.main
    );
    const error = useSelector(({ message }) => message.error);

    const [questionContainerHeight, setQuestionContainerHeight] =
        useState(null);
    const [questionBoxHeight, setQuestionBoxHeight] = useState(0);

    const [answerContainerHeight, setAnswerContainerHeight] = useState(0);

    useEffect(() => {
        dispatch(addQuestions(questionIndex));
    }, []);

    useEffect(() => {
        questionGrid.current !== undefined &&
            setQuestionContainerHeight(questionGrid.current.offsetHeight);

        questionBoxRef.current !== undefined &&
            setQuestionBoxHeight(questionBoxRef.current.offsetHeight);

        answerContainerRef.current !== undefined &&
            setAnswerContainerHeight(answerContainerRef.current.offsetHeight);
    }, [questionBoxHeight, answerContainerHeight]);

    // handle answer options clicked event
    const handleAnswerClick = (answer) => {
        socketFromStore.emit(SOCKET_SEND_EVENTS.ANSWER_QUESTION, {
            roomId: roomId,
            playerId: me.playerId,
            answer: answer,
        });

        // also store the answer clicked
        setSelectedAns(answer);
        setClicked(true);
    };

    const handlePlayerAnswered = useCallback(
        (playerId, answer) => {
            dispatch(playerAnswered(playerId));

            if (playerId == me.playerId) {
                // also store the answer clicked
                setSelectedAns(answer);
                setClicked(true);
            }
        },
        [dispatch]
    );

    //add PLAYER_ANSWERED event,
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.PLAYER_ANSWERED,
        null,
        handlePlayerAnswered
    );

    // handle vote clicked event
    const handleVoteClick = (vote) => {
        socketFromStore.emit(SOCKET_SEND_EVENTS.QUESTION_VOTE, index, vote);

        setVote(vote);
        setVoted(true);
    };

    // check gameStatus and redirect to end page when game is over
    useEffect(() => {
        if (gameStatus === GAME_STATUS.END) {
            let message = { action: "endGame" };
            // method to send message to parent outside iframe
            window.parent.postMessage(message, "*");
            navigate("/end");
        }
    }, [gameStatus, navigate]);

    // receive in-game // question timer
    const handleRoundCountdown = useCallback(
        (questionTimer) => {
            dispatch(addPlayTimer(questionTimer));
        },
        [dispatch]
    );

    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.ROUND_COUNTDOWN,
        null,
        handleRoundCountdown
    );

    const handleRoundEnds = useCallback(
        (data) => {
            const pastPlayers = {};

            players.forEach((player) => {
                pastPlayers[player.id] = player;
            });

            data.playersStats.forEach((player) => {
                if (!pastPlayers[player.id]) return;

                //check if there are changes in bonus and show it
                const pastPlayerBonus = pastPlayers[player.id];
                player.showRibbon = pastPlayerBonus.showRibbon ? true : false;

                //if either does not exist, then skip check
                if (
                    !player.hasOwnProperty("bonusPoints") ||
                    !pastPlayerBonus.hasOwnProperty("bonusPoints") ||
                    !player.hasOwnProperty("answerSpree") ||
                    !pastPlayerBonus.hasOwnProperty("answerSpree")
                ) {
                    return;
                }

                if (
                    player.bonusPoints.length >
                        pastPlayerBonus.bonusPoints.length &&
                    player.answerSpree > pastPlayerBonus.answerSpree
                ) {
                    player.showRibbon = true;
                }

                //if player skips or made a wrong answer, hide ribbon
                if (player.answerSpree <= pastPlayerBonus.answerSpree) {
                    player.showRibbon = false;
                }
            });

            dispatch(updateCorrectAnswer(data.correctAnswer));
            dispatch(
                populatePlayers(putSelfToStart(data.playersStats, me.playerId))
            );
        },
        [players]
    );

    // when round ends
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.ROUND_ENDS,
        null,
        handleRoundEnds
    );

    // receive showNextQuestion event
    const handleRoundStarts = useCallback(
        (questionIndex, maxSeconds) => {
            dispatch(playerReset(["didAnswer", "isCorrect"]));
            setClicked(false);
            setVoted(false);
            dispatch(updateCorrectAnswer(undefined));
            dispatch(updateMaxSeconds(maxSeconds));
            dispatch(addQuestions(questionIndex));
            dispatch(updateGameStatus("in-game"));

            // method to send message to parent outside iframe
            let message = { action: "refreshAds" };
            window.parent.postMessage(message, "*");
        },
        [dispatch]
    );

    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.ROUND_STARTS,
        null,
        handleRoundStarts
    );

    return (
        questionObj !== undefined &&
        (error === "" ? (
            <>
                <CssBaseline />
                <Container
                    maxWidth="md"
                    style={{
                        padding: "0",
                        maxWidth: `750px`,
                    }}
                >
                    <Box
                        container
                        sx={{
                            ml: 0,
                        }}
                        spacing={{ xs: "0" }}
                        className={styles.backgroundColor}
                    >
                        <Box item xs={12}>
                            <Box
                                sx={{
                                    width: { xs: "100%" },
                                    overflow: "hidden",
                                    paddingTop: theme.px10.main,
                                    paddingBottom: theme.px10.main,
                                }}
                            >
                                <Players players={players} />
                            </Box>
                        </Box>
                        <Box
                            item
                            xs={12}
                            ref={questionGrid}
                            sx={{
                                maxHeight: { sm: `30rem` },
                                minHeight: { xs: `30rem`, sm: "35rem" },
                                width: "100%",
                                position: "relative",
                                border: {
                                    xs: "0px",
                                    sm: `3px solid ${theme.palette.wmColor.main}`,
                                },
                                borderRadius: {
                                    xs: "0px",
                                    sm: `${theme.borderRadius.primary}`,
                                },
                            }}
                        >
                            <Box>
                                <Question
                                    questionObj={questionObj}
                                    theme={theme}
                                    uiLabels={uiLabels}
                                    index={index}
                                    questionsCount={questionsCount}
                                    currentCategory={currentCategory}
                                />

                                {/* 2. end of question box*/}
                                <Box
                                    id="answerContainer"
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        width: "100%",
                                        position: "relative",
                                        gap: "12px",
                                        pl: theme.pLeft.secondary,
                                        pr: theme.pRight.secondary,
                                    }}
                                    ref={answerContainerRef}
                                >
                                    {questionObj.answers && (
                                        <MultipleAnswers
                                            questionObj={questionObj}
                                            buttonBackgroundColor={
                                                buttonBackgroundColor
                                            }
                                            questionTimer={questionTimer}
                                            correctAnswer={correctAnswer}
                                            clicked={clicked}
                                            matches={matches}
                                            selectedAns={selectedAns}
                                            handleAnswerClick={
                                                handleAnswerClick
                                            }
                                        />
                                    )}
                                </Box>
                                {/* 3. end of answer box */}
                                {(questionTimer > 0 ||
                                    gameType !== "video") && (
                                    <Timer
                                        answerContainerHeight={
                                            answerContainerHeight
                                        }
                                    />
                                )}
                                {questionTimer === 0 &&
                                    gameType === "video" && (
                                        <QuestionVote
                                            questionObj={questionObj}
                                            voted={voted}
                                            vote={vote}
                                            handleVoteClick={handleVoteClick}
                                            uiLabels={uiLabels}
                                        />
                                    )}
                            </Box>
                            {/* end of contentHolder box*/}
                        </Box>
                    </Box>
                    {message !== "" && (
                        <SnackBar open={openSnackbar} message={message} />
                    )}
                </Container>
            </>
        ) : (
            <ErrorPage />
        ))
    );
};

export default Play;
