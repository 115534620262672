const initialState = {
    index: 0,
    questionObject: undefined,
    questions: [],
    questionsCount: 0,
    maxSeconds: null,
};

const QuestionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ADD_QUESTIONS_OBJECT":
            return Object.assign({}, state, {
                index: action.data,
                questionObject: state.questions[action.data],
            });

        case "UPDATE_QUESTIONS":
            return Object.assign({}, state, {
                questions: action.data,
                questionsCount: action.data.length,
            });

        case "UPDATE_QUESTION_INDEX":
            return Object.assign({}, state, {
                index: action.data,
            });

        case "UPDATE_MAX_SECONDS":
            return Object.assign({}, state, {
                maxSeconds: action.data,
            });

        default:
            return state;
    }
};

//add questions
export const addQuestions = (index) => {
    return async (dispatch) => {
        dispatch({
            type: "ADD_QUESTIONS_OBJECT",
            data: index,
        });
    };
};

//update questions
export const updateQuestions = (questions) => {
    return {
        type: "UPDATE_QUESTIONS",
        data: questions,
    };
};

//update question index
export const updateQuestionIndex = (index) => {
    return {
        type: "UPDATE_QUESTION_INDEX",
        data: index,
    };
};

//update maxSeconds
export const updateMaxSeconds = (seconds) => {
    return {
        type: "UPDATE_MAX_SECONDS",
        data: seconds,
    };
};

export default QuestionsReducer;
