const initialState = {
    videos: [],
};

const VideoReducer = (state = initialState, action) => {
    switch (action.type) {
        case "UPDATE_VIDEOS":
            return Object.assign({}, state, {
                videos: action.data,
            });

        default:
            return state;
    }
};

//get all answers
export const updateVideos = (videos) => {
    return async (dispatch) => {
        dispatch({
            type: "UPDATE_VIDEOS",
            data: videos,
        });
    };
};

export default VideoReducer;
