import { useEffect } from "react";
import io from "socket.io-client";
import { useDispatch, useSelector } from "react-redux";
import { addSocket } from "../reducers/SocketReducer";

let counter = 0;

const useSocket = (token, language, uiLabels) => {
    const dispatch = useDispatch();
    const socketFromStore = useSelector(({ socket }) => socket.socket);

    useEffect(() => {
        if (
            token !== null &&
            counter === 0 &&
            socketFromStore === null &&
            language &&
            uiLabels
        ) {
            // console.log(`connecting... ${process.env.REACT_APP_SOCKET_URL}`);
            const socket = io(process.env.REACT_APP_SOCKET_URL, {
                query: {
                    token: token,
                    language: language,
                    uiLabels: uiLabels.join(","),
                },
            });

            counter++;
            //client side
            socket.on("connect", () => {
                // console.log(`Connected to socket server with id: ${socket.id}`);
            });

            dispatch(addSocket(socket, true));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, language, uiLabels]);
};

export default useSocket;
