/* eslint-disable no-unused-vars */
/* eslint-disable default-case */
import React from "react";
import { useTheme } from "@emotion/react";
import { Box } from "@mui/material";
import GeneralButton from "../Button/Button";
import playLong from "../InviteSection/playLong.png";
import { useDispatch, useSelector } from "react-redux";
import { getLabel, titleCase } from "../../helpers/Global";
import { SOCKET_SEND_EVENTS } from "../../helpers/constant";
import { addAlert, removeAlert } from "../../reducers/AlertReducer";

export default function EndScreenButtons({ uiLabels }) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const gameType = useSelector(({ game }) => game.gameType);
    const currentCategory = useSelector(
        ({ settings }) => settings.currentCategory
    );
    const currentPid = useSelector(({ settings }) => settings.currentPid);

    const me = useSelector(({ player }) => player);
    const players = useSelector(({ game }) => game.gamePlayers);

    const socketFromStore = useSelector(({ socket }) => socket.socket);

    function handleShareResults() {
        let url = null;
        let title = null;
        const player = players.find((player) => {
            return player.id === me.playerId;
        });
        switch (gameType) {
            case "category":
                url = `${process.env.REACT_APP_URL}/${
                    process.env.REACT_APP_END_POINT
                }/${titleCase(currentCategory.replace(/ /g, "+"))}`;
                title = `I tested my trivia power in the ${
                    currentCategory && currentCategory.toLowerCase() === "all"
                        ? ""
                        : currentCategory
                } category and scored ${
                    player.score
                } point(s)! Test your trivia power too!`;
                break;

            case "video":
                url = `${process.env.REACT_APP_URL}/${process.env.REACT_APP_END_POINT}/${currentPid}`;
                title = `I tested my trivia power and scored ${player.score} point(s)! Test your trivia power too!`;
                break;
        }

        let message = { action: "shareResults", url: url, title: title };
        //method to send message to parent outside iframe
        window.top.postMessage(message, "*");
    }

    function handleBackToLobby() {
        dispatch(
            addAlert({
                title: `Confirm`,
                message: "Would you like to share your feedback ?",
                buttons: [
                    {
                        text: "No",
                        callback: () => {
                            socketFromStore.emit(
                                SOCKET_SEND_EVENTS.PLAY_AGAIN,
                                me.playerId
                            );
                            dispatch(removeAlert());
                        },
                    },
                    {
                        text: "Yes",
                        callback: () => {
                            let message = {
                                action: "triggerFeedback",
                                url: process.env.REACT_APP_FEEDBACK_URL,
                            };
                            window.top.postMessage(message, "*");

                            socketFromStore.emit(
                                SOCKET_SEND_EVENTS.PLAY_AGAIN,
                                me.playerId
                            );
                            dispatch(removeAlert());
                        },
                    },
                ],
            })
        );
    }

    return (
        <Box display="flex" alignItems="center" justifyContent="center">
            <Box
                display="flex"
                sx={{
                    width: { xs: "100%", sm: "80%", md: "50%" },
                    px: { xs: 2, md: 0 },
                    py: 0,
                    pb: theme.px16.main,
                }}
            >
                <GeneralButton
                    bgcolor={theme.palette.secondaryButton.main}
                    color={theme.palette.secondaryButton.textColor}
                    mRight={"10px"}
                    hoverColor={theme.palette.hoverColor.main}
                    handleButtonClick={handleShareResults}
                    id={"trivia_share_results"}
                    minHeight={"48px"}
                    maxHeight={"75px"}
                >
                    {getLabel(uiLabels, "btnShareResult", "Share Results")}
                </GeneralButton>
                <GeneralButton
                    bgcolor={theme.palette.secondaryButton.main}
                    color={theme.palette.secondaryButton.textColor}
                    backgroundImage={playLong}
                    filter={"brightness(1.5)"}
                    handleButtonClick={handleBackToLobby}
                    id={"trivia_back_to_lobby"}
                    minHeight={"48px"}
                    maxHeight={"75px"}
                >
                    {getLabel(uiLabels, "btnBackToLobby", "Back to Lobby")}
                </GeneralButton>
            </Box>
        </Box>
    );
}
