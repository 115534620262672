import React from "react";
import { CssBaseline, Button, Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const Timer = () => {
    const theme = useTheme();
    const questionTimer = useSelector(({ timer }) => timer.playTimer);

    const maxSeconds = useSelector(({ question }) => question.maxSeconds);
    const widthPerSeconds = (100 / maxSeconds).toFixed(2);
    const widthMultiple = maxSeconds - questionTimer;

    const matchesVerySmall = useMediaQuery("(max-width:380px)");
    //color for countdown
    const countDownColor = () => {
        let colorCode;
        switch (questionTimer) {
            case 15:
                colorCode = "#54171d";
                break;
            case 14:
                colorCode = "#e8caa2";
                break;
            case 13:
                colorCode = "#148c78";
                break;
            case 12:
                colorCode = "#85d4c7";
                break;
            case 11:
                colorCode = "#203045";
                break;
            case 10:
                colorCode = "#DCC400";
                break;
            case 9:
                colorCode = "#C16F4A";
                break;
            case 8:
                colorCode = "#e083d3";
                break;
            case 7:
                colorCode = "#9A45AA";
                break;
            case 6:
                colorCode = "#81e052";
                break;
            case 5:
                colorCode = "#D35B67";
                break;
            case 4:
                colorCode = "#12a110";
                break;
            case 3:
                colorCode = "#733546";
                break;
            case 2:
                colorCode = "#4DB2A3";
                break;
            case 1:
                colorCode = "#d10628";
                break;
            default:
                colorCode = "";
                break;
        }

        return colorCode;
    };

    return (
        <>
            <CssBaseline />
            <Box
                sx={{
                    width: "100%",
                    pl: theme.pLeft.secondary,
                    pr: theme.pRight.secondary,
                    position: { xs: "fixed", sm: "absolute" },
                    bottom: {
                        xs: "20px",
                    },
                }}
            >
                <Box textAlign="center">
                    {/* show seconds left , until any option is clicked */}
                    {/* {clicked === false && ( */}
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            paddingTop: { xs: "8px", md: "5px" },
                        }}
                    >
                        <Typography
                            sx={{ fontSize: { xs: "17px", md: "25px" } }}
                        >
                            <span
                                style={{
                                    color: countDownColor(),
                                    fontWeight: "bold",
                                    verticalAlign: "middle",
                                }}
                            >
                                {questionTimer > 0 ? questionTimer : ""}
                            </span>
                        </Typography>
                        <div
                            style={{
                                display: "inline",
                                fontSize: "15px",
                                paddingLeft: "10px",
                                paddingTop: "5px",
                            }}
                        >
                            <span style={{ verticalAlign: "super" }}>
                                {questionTimer > 1
                                    ? "seconds left"
                                    : questionTimer === 1
                                    ? "second left"
                                    : ""}
                            </span>
                        </div>
                    </Box>
                    {/* )} */}
                </Box>
                <Button
                    sx={{
                        width: "100%",
                        backgroundColor: "#fff",
                        height: "8px",
                        borderRadius: "0",
                        display: "flex",
                        justifyContent: "initial",
                        padding: "0",
                    }}
                    disabled={true}
                >
                    <Box
                        style={{
                            //backgroundColor: countDownColor(),
                            backgroundColor: "#5797c3",
                            width: `${widthMultiple * widthPerSeconds}%`,
                            height: "100%",
                        }}
                    ></Box>
                </Button>
            </Box>
        </>
    );
};

export default Timer;
