/* eslint-disable default-case */
import React, { useCallback, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Lobby from "./components/Lobby/Lobby";
import Play from "./components/Play/Play";
import useSocketEvents from "./hooks/useSocketEvents";
import {
    SOCKET_ACTIONS,
    SOCKET_RECEIVE_EVENTS,
    GAME_STATUS,
    UPDATE_UI_LABELS,
} from "./helpers/constant";
import {
    populatePlayers,
    updateRoomId,
    updateGameHost,
    updateGameStatus,
    playerRemoved,
    playerJoined,
    addGameType,
    updateLanguages,
    updateUILabels,
} from "./reducers/GameReducer";
import { updateCorrectAnswer } from "./reducers/AnswersReducer";
import { addMessage, addError } from "./reducers/MessageReducer";
import { addStartTimer } from "./reducers/TimerReducer";
import {
    updateQuestionIndex,
    updateQuestions,
} from "./reducers/QuestionsReducer";
import { useDispatch, useSelector } from "react-redux";
import EndScreen from "./components/EndScreen/EndScreen";
import { putSelfToStart, titleCase, updateMojoPoints } from "./helpers/Global";

import {
    addTitle,
    addPid,
    addMode,
    addCategory,
    addTriviaImage,
} from "./reducers/SettingsReducer";
import BackdropLoading from "./components/BackdropLoading/BackdropLoading";
import Languages from "./components/Languages/Languages";
import { Box } from "@mui/material";
import AlertDialog from "./components/AlertDialog/AlertDialog";
import { addAlert, removeAlert } from "./reducers/AlertReducer";
import { updateInviteCode } from "./reducers/PlayerReducer";

const GameRoutes = () => {
    const dispatch = useDispatch();
    const me = useSelector(({ player }) => player);
    const gameStatus = useSelector(({ game }) => game.gameStatus);

    const gameType = useSelector(({ game }) => game.gameType);
    const currentCategory = useSelector(
        ({ settings }) => settings.currentCategory
    );
    const currentPid = useSelector(({ settings }) => settings.currentPid);

    const languages = useSelector(({ game }) => game.languages);

    const language = useSelector(({ game }) => game.language);

    const uiLabels = useSelector(({ game }) => game.uiLabels);

    const questions = useSelector(({ question }) => question.questions);

    const [isLoading, setIsLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState("");

    const error = useSelector(({ message }) => message.error);

    const appendRoomId = (roomId) => {
        //update URL to include roomId
        var queryParams = new URLSearchParams(window.location.search);

        // Set new or modify existing parameter value.
        queryParams.set("roomId", roomId);

        // Replace current querystring with the new one.
        window.history.replaceState(null, null, "?" + queryParams.toString());

        //update parent url as well with roomId
        let message = { action: "updateRoomId", roomId: roomId };
        //method to send message to parent outside iframe
        window.top.postMessage(message, "*");
    };

    useEffect(() => {
        //update parent url as well with roomId
        let message = {
            action: "updateLanguageList",
            languages: languages,
        };
        //method to send message to parent outside iframe
        window.top.postMessage(message, "*");
    }, [languages]);

    useEffect(() => {
        if (error != "") {
            setIsLoading(false);
            return;
        }

        if (gameStatus === GAME_STATUS.END) {
            setIsLoading(false);
            return;
        }

        if (questions.length === 0) {
            setLoadingMessage(`Loading game info...`);
            setIsLoading(true);
            return;
        }

        setIsLoading(false);
    }, [questions, gameStatus, error]);

    // receives game-details when new player joins
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.GAME_DETAILS,
        null,
        ({
            players,
            gameStatus,
            gameSettings,
            gameHost,
            roomId,
            languages,
            questionIndex,
            inviteCode,
        }) => {
            // console.log(
            //     gameStatus,
            //     players,
            //     gameSettings,
            //     languages,
            //     inviteCode
            // );
            dispatch(
                addMessage(
                    `<span style='color:#00b3e2'>Welcome</span> to the waiting lobby`,
                    true
                )
            );

            //update url add roomId
            appendRoomId(roomId);

            //update roomId
            dispatch(updateRoomId(roomId));

            //update gameHost
            dispatch(updateGameHost(gameHost));

            //update gameType
            dispatch(addGameType(gameSettings.gameType));

            //add title, pid, gameMode, category, triviaImage
            dispatch(addTitle(gameSettings.triviaTitle));
            dispatch(addPid(gameSettings.videoId));
            dispatch(addCategory(gameSettings.category));
            dispatch(addMode(gameSettings.gameMode));
            dispatch(addTriviaImage(gameSettings.triviaImage));

            //update gameStatus
            dispatch(updateGameStatus(gameStatus));
            //update players
            dispatch(populatePlayers(players));

            dispatch(updateLanguages(languages ?? []));

            dispatch(updateQuestionIndex(questionIndex ?? 0));

            dispatch(updateInviteCode(inviteCode ?? null));
        }
    );

    // receive update status
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.UPDATE_STATUS,
        null,
        ({ status }) => {
            dispatch(updateGameStatus(status));
        }
    );

    // receive player removed message event
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.PLAYER_REMOVED,
        null,
        (playerId, playerName) => {
            dispatch(
                addMessage(
                    `<span style='color:#00b3e2'>${playerName}</span> has been removed`,
                    true
                )
            );
            // fix for player being disappearing after game end/disconnect event in the backend
            gameStatus !== GAME_STATUS.END && dispatch(playerRemoved(playerId));

            me.playerId == playerId &&
                dispatch(addError("You have been removed from the game"));
        }
    );

    // receive player joined event
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.PLAYER_JOINED,
        null,
        (player) => {
            dispatch(
                addMessage(
                    `<span style='color:#00b3e2'>${player.name}</span> joined the game`,
                    true
                )
            );
            dispatch(playerJoined(player));
        }
    );

    // receive starting game timer
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.START_COUNTDOWN,
        null,
        (timer, status) => {
            dispatch(addStartTimer(timer));
            dispatch(updateGameStatus(status));
        }
    );

    //callback when token authentication fails
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.CONNECT_ERROR,
        null,
        (err) => {
            //on connection refused, do something...
            console.log(err.message); // prints the message associated with the error
        }
    );

    //callback when Host changes game settings
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.UPDATED_SETTINGS,
        null,
        (gameSettings) => {
            // console.log("UPDATED SETTINGS", gameSettings);
            dispatch(addGameType(gameSettings.gameType));

            //add title, pid, gameMode, category, triviaImage
            dispatch(addTitle(gameSettings.triviaTitle));
            dispatch(addPid(gameSettings.videoId));
            dispatch(addCategory(gameSettings.category));
            dispatch(addMode(gameSettings.gameMode));
            dispatch(addTriviaImage(gameSettings.triviaImage));
        }
    );

    //callback when Host changes game settings
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.ROOM_FULL,
        null,
        (errorMessage) => {
            dispatch(addError(errorMessage));
        }
    );

    // when game ends
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.GAME_ENDS,
        null,
        (players) => {
            // console.log("GAME ENDS", players);
            dispatch(populatePlayers(players));
            dispatch(updateGameStatus("end"));

            const player = players.find((p) => {
                return p.id === me.playerId;
            });

            if (player && player.message && player.message !== "") {
                let earnedPoints = 0;
                const message = player.bonusPoints.map((point) => {
                    earnedPoints += point.points;
                    return point.message;
                });

                updateMojoPoints(earnedPoints);
                dispatch(
                    addAlert({
                        title: "Mojo Points Earned!",
                        message: message.join("<br>"),
                        buttons: [
                            {
                                text: "Ok",
                                callback: () => {
                                    dispatch(removeAlert());
                                },
                            },
                        ],
                    })
                );
            }
        }
    );

    //callback when players update
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.PLAYERS_UPDATE,
        null,
        (players, gameHost) => {
            dispatch(updateGameHost(gameHost));
            dispatch(populatePlayers(players));
        }
    );

    //callback when REDIRECT_NEW_ROOM
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.REDIRECT_NEW_ROOM,
        null,
        (roomId) => {
            let url = null;
            switch (gameType) {
                case "category":
                    url = `${process.env.REACT_APP_WM_URL}/${
                        process.env.REACT_APP_END_POINT
                    }/${titleCase(currentCategory.replace(/ /g, "+"))}`;
                    break;

                case "video":
                    url = `${process.env.REACT_APP_WM_URL}/${process.env.REACT_APP_END_POINT}/${currentPid}`;
                    break;
            }
            window.top.location.href = `${url}/room/${roomId}`;
        }
    );

    //callback to recevie update questions
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.UPDATE_QUESTIONS,
        null,
        (questions) => {
            dispatch(updateQuestions(questions));
        }
    );

    // receive player disconnected from socket event
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.PLAYER_DISCONNECTED,
        null,
        (playerId, playerName) => {
            dispatch(
                addMessage(
                    `<span style='color:#00b3e2'>${playerName}</span> has disconnected`,
                    true
                )
            );
            gameStatus !== GAME_STATUS.END && dispatch(playerRemoved(playerId));
        }
    );

    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.HANDLE_ERROR,
        null,
        ({ title, message, action, alert = false }) => {
            if (!alert) {
                dispatch(addError(message));
                return;
            }

            const btnText = action ? "Ok" : "Ok";
            let btnCallback = () => {
                //update parent url as well with roomId
                let message = { action: "refreshPage" };
                //method to send message to parent outside iframe
                window.top.postMessage(message, "*");
                dispatch(removeAlert());
            };

            dispatch(
                addAlert({
                    title: title ?? "",
                    message: message ?? "",
                    buttons: [
                        {
                            text: btnText,
                            callback: btnCallback,
                        },
                    ],
                })
            );
        }
    );

    const handleUILables = useCallback(
        (payload) => {
            const copyLabels = !uiLabels ? {} : { ...uiLabels };
            const keys = Object.keys(payload ?? {});

            if (keys.length === 0) {
                return;
            }

            keys.forEach((key) => {
                copyLabels[key] = payload[key];
            });

            dispatch(updateUILabels(copyLabels));

            let message = {
                action: UPDATE_UI_LABELS,
                uiLabels: copyLabels,
            };
            //method to send message to parent outside iframe
            window.top.postMessage(message, "*");
        },
        [dispatch, uiLabels]
    );

    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.UILABELS,
        null,
        handleUILables
    );

    // add event to refresh page
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.REFRESH,
        null,
        () => {
            //update parent url as well with roomId
            let message = { action: "refreshPage" };
            //method to send message to parent outside iframe
            window.top.postMessage(message, "*");
        }
    );

    // add event to receive loading messages
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.LOADING,
        null,
        ({ message }) => {
            console.log(message);
            setLoadingMessage(message);
            setIsLoading(true);
        }
    );

    return (
        <>
            {/* <Box display={"flex"} justifyContent={"right"} sx={{ p: 1 }}>
                {languages && languages.length > 0 && (
                    <Languages languages={languages} language={language} />
                )}
            </Box> */}
            <Routes>
                <Route
                    exact
                    path="/"
                    element={<Lobby gameType={gameType} uiLabels={uiLabels} />}
                />
                <Route
                    exact
                    path="/play"
                    element={<Play uiLabels={uiLabels} />}
                />
                <Route
                    exact
                    path="/end"
                    element={<EndScreen uiLabels={uiLabels} />}
                />
            </Routes>
            <AlertDialog />
            <BackdropLoading open={isLoading} message={loadingMessage} />
        </>
    );
};

export default GameRoutes;
