import { Typography, Button, Grid, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import FlagIcon from "@mui/icons-material/Flag";
import { getLabel } from "../../helpers/Global";

const QuestionVote = ({
    questionObj,
    voted,
    vote,
    handleVoteClick,
    uiLabels,
}) => {
    const theme = useTheme();
    return (
        <Box
            item
            xs={6}
            sx={{
                position: { xs: "fixed", sm: "absolute" },
                px: {
                    xs: "1rem",
                    md: "0",
                },
                bottom: "10px",
                left: "0",
                justifyContent: "center",
                textAlign: "center",
                width: "100%",
            }}
        >
            <Button
                name={questionObj.id}
                sx={{
                    height: "3.2rem",
                    borderRadius: theme.borderRadius.primary,
                    border: `3px solid ${theme.palette.voteButton.main}`,
                    color: theme.palette.voteButton.main,
                    width: "100%",
                    maxWidth: "19rem",
                    textTransform: "capitalize",
                    opacity: voted ? "0.5" : "1",
                    "&:disabled": {
                        color: theme.palette.voteButton.main,
                    },
                }}
                onClick={() => handleVoteClick(false)}
                disabled={voted}
            >
                <Typography
                    variant="body1"
                    sx={{
                        fontSize: "1rem",
                        lineHeight: "2rem",
                    }}
                >
                    <FlagIcon
                        sx={{
                            verticalAlign: "bottom",
                            paddingRight: ".5rem",
                            fontSize: "2rem",
                        }}
                    />
                    {getLabel(
                        uiLabels,
                        "labelQuestionOffTopic",
                        "This question is off-topic"
                    )}
                </Typography>
            </Button>
            <Typography
                variant="body1"
                sx={{
                    visibility: voted ? "visible" : "hidden",
                    fontSize: ".8rem",
                    lineHeight: "1.2rem",
                    marginTop: ".2rem",
                }}
            >
                {getLabel(
                    uiLabels,
                    "labelThanksForHelpingUsOut",
                    "Thanks for helping us out!"
                )}
            </Typography>
        </Box>
    );
};

export default QuestionVote;
