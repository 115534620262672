import { useSelector } from "react-redux";
import { useEffect } from "react";
import { SOCKET_ACTIONS } from "../helpers/constant";

// to send emit
//useSocketEvents(SOCKET_ACTIONS.SEND, SOCKET_SEND_EVENTS.SOME_ACTIONS, [array-of-parameters]);

// to receive message
//useSocketEvents(SOCKET_ACTIONS.RECEIVE, SOCKET_RECEIVE_EVENTS.SOME_ACTIONS, null, (SOMECALLBACKS)=> {});

const useSocketEvents = (
    send = SOCKET_ACTIONS.SEND,
    eventName,
    messageParameters = [],
    callback = null,
    printLog = false
) => {
    const socketFromStore = useSelector(({ socket }) => socket.socket);

    useEffect(() => {
        if (socketFromStore !== null) {
            if (send) {
                if (printLog) {
                    console.log(`Emit ${eventName}`, messageParameters);
                }
                socketFromStore.emit(eventName, ...messageParameters);
            } else {
                // console.log("Receive", eventName);
                socketFromStore.on(
                    eventName,
                    callback
                        ? callback
                        : () => {
                              //   console.log("no callback");
                          }
                );
            }
        }
        return () => {
            if (socketFromStore !== null) {
                // console.log(`removing...${eventName}`);
                socketFromStore.off(eventName);
            }
        };
    }, [send, eventName, socketFromStore, callback]);
};

export default useSocketEvents;
