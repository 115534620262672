/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState, useRef } from "react";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import {
    Button,
    Dialog,
    DialogContent,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Carousel from "react-material-ui-carousel";
import howTo1 from "./how-to-1.svg";
import howTo2 from "./how-to-2.svg";
import howTo3 from "./how-to-3.svg";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import RemoveIcon from "@mui/icons-material/Remove";
import { getLabel } from "../../helpers/Global";

const HowTo = ({ uiLabels }) => {
    const theme = useTheme();
    const dialogContainer = useRef();
    const howToContainer = useRef();

    const [dialogWidth, setDialogWidth] = useState(
        Math.min(window.screen.width * 0.75, 600)
    );
    const [smDialogWidth, setSMDialogWidth] = useState(
        Math.min(window.screen.width * 0.5, 600)
    );

    const [open, setOpen] = React.useState(false);

    let [desktopHowToHeight, setDesktopHowToHeight] = useState("100%");

    const handleWindowResize = () => {
        setDialogWidth(Math.min(window.screen.width * 0.8, 600));
        setSMDialogWidth(Math.min(window.screen.width * 0.5, 600));

        setDesktopHowToHeight(howToContainer.current.offsetHeight - 71);
    };

    useEffect(() => {
        handleWindowResize();
        window.addEventListener("resize", handleWindowResize);

        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, []);

    const handleOpenDialog = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    var items = [
        {
            icon: howTo1,
            description: getLabel(
                uiLabels,
                "messageHowTo1",
                "Choose a video or a category to play questions from!"
            ),
        },
        {
            icon: howTo2,
            description: getLabel(
                uiLabels,
                "messageHowTo2",
                "Pick what you think is right—the earlier, the more points!"
            ),
        },
        {
            icon: howTo3,
            description: getLabel(
                uiLabels,
                "messageHowTo3",
                "Find out how well you played & win exclusive deals!"
            ),
        },
    ];

    function Item(props) {
        return (
            <Box sx={{ px: "40px", mt: "1rem", mb: "2rem" }}>
                <Box display="flex" alignItems="center" justifyContent="center">
                    <Box
                        sx={{
                            width: {
                                xs: theme.howTo.imageSize.xs,
                                md: theme.howTo.imageSize.md,
                                xl: theme.howTo.imageSize.xl,
                            },
                            height: {
                                xs: theme.howTo.imageSize.xs,
                                md: theme.howTo.imageSize.md,
                                xl: theme.howTo.imageSize.xl,
                            },
                        }}
                    >
                        <img
                            loading="lazy"
                            src={props.item.icon}
                            alt={props.item.icon}
                            style={{ width: "100%", height: "100%" }}
                        />
                    </Box>
                </Box>

                <Typography
                    variant="body1"
                    align="center"
                    sx={{
                        color: "#fff",
                        lineHeight: { xs: "1.5rem", md: "2rem" },
                        fontSize: { xs: "1rem", md: "1.3rem" },
                        maxWidth: { xs: "90%", md: "400px" },
                        margin: "0 auto",
                    }}
                >
                    {props.item.description}
                </Typography>
            </Box>
        );
    }

    function HowToSection(props) {
        return (
            <Box
                sx={{
                    width: "100%",
                    height: "100%",
                }}
            >
                <Typography
                    noWrap
                    align="center"
                    variant="h6"
                    sx={{
                        textTransform: "uppercase",
                        color: "#fff",
                        fontSize: "1.1rem",
                    }}
                >
                    {getLabel(uiLabels, "labelHowTo", "How to Play")}
                </Typography>
                <Carousel
                    navButtonsAlwaysVisible="true"
                    autoPlay="true"
                    interval="3000"
                    navButtonsProps={{
                        style: {
                            backgroundColor: "transparent",
                            borderRadius: 0,
                            padding: 0,
                            margin: 0,
                            marginTop: "-25%",
                        },
                    }}
                    NextIcon={
                        <ChevronRightIcon
                            sx={{
                                color: `${theme.palette.wmColor.main}`,
                                fontWeight: 900,
                                fontSize: "2em",
                            }}
                        />
                    }
                    PrevIcon={
                        <ChevronLeftIcon
                            sx={{
                                color: `${theme.palette.wmColor.main}`,
                                fontWeight: 900,
                                fontSize: "2em",
                            }}
                        />
                    }
                    IndicatorIcon={
                        <RemoveIcon
                            sx={{
                                fontWeight: "bold",
                                fontSize: "1.7em",
                            }}
                        />
                    }
                    indicatorContainerProps={{
                        style: {
                            marginTop: "25px",
                        },
                    }}
                    activeIndicatorIconButtonProps={{
                        style: {
                            color: `${theme.palette.wmColor.main}`,
                        },
                    }}
                    sx={{
                        "& .MuiIconButton-root": {
                            verticalAlign: "text-bottom",
                        },
                    }}
                >
                    {items.map((item, i) => (
                        <Item key={i} item={item} />
                    ))}
                </Carousel>
            </Box>
        );
    }

    return (
        <>
            <Accordion
                sx={{
                    backgroundColor: "transparent",
                    border: {
                        sm: `3px solid ${theme.palette.wmColor.main}`,
                    },
                    borderRadius: `${theme.borderRadius.primary}!important`,
                    margin: "0!important",
                    py: 0,
                }}
            >
                <AccordionSummary
                    expandIcon={
                        <ArrowDropDownIcon
                            sx={{
                                fontSize: {
                                    xs: "2rem",
                                    sm: "4rem",
                                },
                                color: theme.palette.wmColor.main,
                            }}
                        />
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                        margin: "0!important",
                    }}
                >
                    <Typography
                        variant="h6"
                        sx={{
                            color: theme.palette.wmColor.main,
                            fontSize: "1.1rem",
                        }}
                    >
                        {getLabel(uiLabels, "labelHowTo", "How to Play")}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ py: 0 }}>
                    <Box
                        ref={howToContainer}
                        sx={{
                            width: "100%",
                            height: "100%",
                            border: {
                                xs: `3px solid ${theme.palette.wmColor.main}`,
                                md: `0 none`,
                            },
                            borderRadius: `${theme.borderRadius.primary}!important`,
                            pt: {
                                xs: `.5rem`,
                                md: `0`,
                            },
                            mb: {
                                xs: `.5rem`,
                                md: `0`,
                            },
                        }}
                    >
                        <Box
                            sx={{
                                width: "100%",
                                height: "100%",
                            }}
                        >
                            <HowToSection height={desktopHowToHeight} />
                        </Box>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </>
    );
};

export default HowTo;
