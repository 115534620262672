import React, { useEffect, useState } from "react";

import { Box, Typography } from "@mui/material";

import cssStyle from "./Ribbon.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAward } from "@fortawesome/free-solid-svg-icons";

export default function Ribbon({ bonusPoints, showRibbon }) {
    const [points, setPoints] = useState(0);
    const [animation, setAnimation] = useState("");

    useEffect(() => {
        let totalPoints = 0;
        bonusPoints.forEach((element) => {
            totalPoints += element.points;
        });

        setPoints(totalPoints);

        setAnimation(cssStyle.bounce);

        setTimeout(() => {
            setAnimation("");
        }, 1500);
    }, [bonusPoints.length]);

    return (
        <Box
            className={`${cssStyle.loyaltyRibbonBox} ${animation}`}
            sx={{
                display: showRibbon ? "block" : "none",
            }}
        >
            <FontAwesomeIcon
                icon={faAward}
                className={cssStyle.loyaltyRibbon}
            />
            <Typography
                className={cssStyle.loyaltyRibbonText}
                variant="overline"
                sx={{
                    fontSize: {
                        xs: "0.55rem",
                        md: "0.62rem",
                    },
                }}
            >
                {points}
            </Typography>
        </Box>
    );
}
