import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
    palette: {
        wmColor: { main: "#00b3e2" },
        textColor: { main: "#fafafa" },
        backgroundColor: { main: "#181818" },
        gameModeActive: { main: "#2B235C" },
        secondaryButton: { main: "#3f3f3f" },
        voteButton: { main: "#E5E5E5" },
        hoverColor: { main: "#5c636a" },
        correct: { main: "green" },
        inCorrect: { main: "red" },
    },
    borderRadius: {
        primary: "8px",
        secondary: "4px",
    },
    pLeft: {
        main: "8px",
        secondary: "15px",
    },
    pRight: {
        main: "8px",
        secondary: "15px",
    },
    px8: {
        main: "8px",
    },
    px10: {
        main: "10px",
    },
    px16: {
        main: "16px",
    },
    categoryButtons: {
        px: { xs: "8px", md: "20%" },
    },
    howTo: {
        imageSize: { xs: "90px", md: "120px" },
        container: { mobile: 200 },
    },
});
