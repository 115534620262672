import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import {
    Box,
    ImageListItem,
    ImageListItemBar,
    CircularProgress,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { addGameType } from "../../reducers/GameReducer";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
    addTitle,
    addPid,
    addTriviaImage,
    addCategory,
} from "../../reducers/SettingsReducer";
import { Button } from "@mui/material";
import ShuffleIcon from "@mui/icons-material/Shuffle";
import { IS_HOST, getLabel, isMobileOrTablet } from "../../helpers/Global";
import {
    GAME_TYPE,
    GAME_MODE,
    SOCKET_SEND_EVENTS,
    GAME_STATUS,
} from "../../helpers/constant";

const LIMIT = 4;

const RelatedVideos = ({ sprintContainerHeight, uiLabels }) => {
    const getRandomVideos = async (shuffle = false) => {
        setLoader(true);

        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };

            const data = {
                roomId: roomId,
                shuffle: shuffle,
                language: language,
            };

            const response = await axios.post(
                `${process.env.REACT_APP_SOCKET_URL}/videos`,
                data,
                config
            );

            if (response.data && response.data.result.length > 0) {
                setSimilarvideos(response.data.result);
                if (!shuffle) {
                    //find current video and click it
                    const video = response.data.result.find(
                        (video) => video.pid == currentPid
                    );
                    video && handleImageClick(video);
                }
            }
        } catch (e) {
            console.log(e);
        }

        setLoader(false);
    };

    const socketFromStore = useSelector(({ socket }) => socket.socket);
    const theme = useTheme();
    const dispatch = useDispatch();
    const btnShuffle = useRef();

    let [loader, setLoader] = useState(false);
    let [similarVideos, setSimilarvideos] = useState([]);
    let [videoResultContainerHeight, setVideoResultContainerHeight] =
        useState("auto");

    let status = useSelector(({ game }) => game.gameStatus);
    const roomId = useSelector(({ game }) => game.roomId);
    const language = useSelector(({ game }) => game.language);

    let currentTitle = useSelector(({ settings }) => settings.currentTitle);
    let currentPid = useSelector(({ settings }) => settings.currentPid);

    const gameMode = useSelector(({ settings }) => settings.currentMode);
    const token = useSelector(({ token }) => token.token);

    const isHost = IS_HOST(
        useSelector(({ game }) => game.gameHost),
        useSelector(({ player }) => player.playerId)
    );

    const handleImageClick = (video) => {
        dispatch(addPid(video.pid));
        dispatch(addTitle(video.title));
        dispatch(addTriviaImage(video.thumbnails.hd));
        dispatch(addGameType(GAME_TYPE.VIDEO));
        dispatch(addCategory(null));

        const newConfig = {
            gameType: GAME_TYPE.VIDEO,
            gameMode: GAME_MODE.SPRINT,
            videoId: video.pid,
            triviaTitle: video.title,
            triviaImage: video.thumbnails.hd,
        };
        socketFromStore.emit(
            SOCKET_SEND_EVENTS.UPDATE_SETTINGS,
            roomId,
            newConfig
        );
    };

    const matches = useMediaQuery("(min-width:750px)");

    useEffect(() => {
        if (
            status !== null &&
            status === GAME_STATUS.WAITING &&
            gameMode === GAME_MODE.SPRINT
        ) {
            getRandomVideos();
        }
    }, [status]);

    useEffect(() => {
        let newHeight = "";
        if (btnShuffle.current !== null && btnShuffle.current !== undefined) {
            newHeight = `${
                sprintContainerHeight - btnShuffle.current.offsetHeight
            }`;
        }
        setVideoResultContainerHeight(newHeight);
    }, [sprintContainerHeight]);

    return (
        <Box
            sx={{
                height: { xs: "100%" },
                position: "relative",
            }}
        >
            <Box
                sx={{
                    display: "grid",
                    gridTemplateColumns: {
                        md: "repeat(2, 1fr)",
                        xs: "repeat(2, 1fr)",
                    },
                    gridGap: ".5rem",
                    height: {
                        xs: "100%",
                        md: `calc(100% - 45px)`,
                    },
                }}
            >
                {similarVideos.map((video) => (
                    <ImageListItem
                        key={video.thumbnails.medium}
                        sx={{
                            cursor: "pointer",
                            opacity: video.pid == currentPid ? 1 : 0.4,
                            border:
                                video.pid == currentPid
                                    ? {
                                          xs: "",
                                          sm: `3px solid ${theme.palette.wmColor.main}`,
                                      }
                                    : "none",
                            borderRadius: {
                                xs: 0,
                                sm: theme.borderRadius.primary,
                            },
                            height: "auto",
                            overflow: "hidden",
                        }}
                        onClick={() => handleImageClick(video)}
                    >
                        <img
                            src={`${video.thumbnails.medium}`}
                            srcSet={`${video.thumbnails.medium}`}
                            alt={video.title}
                            style={{
                                borderRadius: matches
                                    ? theme.borderRadius.secondary
                                    : "0px",
                            }}
                            sx={{
                                width: "100%",
                            }}
                            loading="lazy"
                        />
                        <ImageListItemBar
                            title={video.title}
                            sx={{
                                borderBottomLeftRadius:
                                    theme.borderRadius.secondary,
                                borderBottomRightRadius:
                                    theme.borderRadius.secondary,
                                " .MuiImageListItemBar-titleWrap": {
                                    padding: { xs: "2px", md: "4px" },
                                },
                                " .MuiImageListItemBar-title": {
                                    fontSize: { xs: "0.8rem" },
                                    lineHeight: { xs: "1.3" },
                                },
                            }}
                        />
                    </ImageListItem>
                ))}
            </Box>

            <Box
                ref={btnShuffle}
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                    pt: theme.px16.main,
                    display: "flex",
                }}
            >
                <Button
                    variant="contained"
                    sx={{
                        backgroundColor: "#3F3F3F",
                        ":hover": {
                            bgcolor: "#3F3F3F",
                        },
                        py: ".5rem",
                        px: "1.2rem",
                    }}
                    startIcon={<ShuffleIcon />}
                    onClick={() => {
                        getRandomVideos(true);
                    }}
                >
                    {getLabel(uiLabels, "btnShuffleVideos", "Shuffle Videos")}
                </Button>
            </Box>

            {loader && (
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        top: 0,
                        left: 0,
                    }}
                >
                    <CircularProgress />
                </Box>
            )}
        </Box>
    );
};

export default RelatedVideos;
