/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import { Box, Container, CssBaseline, Grid } from "@mui/material";

import Players from "../Players/Players";
import LobbySettings from "../LobbySettings/LobbySettings";
import styles from "./Lobby.module.css";
import { useLocation } from "react-router-dom";
import HowTo from "../HowTo/HowTo";
import { useTheme } from "@mui/material/styles";
import useSocket from "../../hooks/useSocket";
import { addToken } from "../../reducers/TokenReducer";
import { saveYourProfile } from "../../reducers/PlayerReducer";
import {
    updateLanguage,
    updateRoomId,
    updateUILabels,
} from "../../reducers/GameReducer";
import { useSelector, useDispatch } from "react-redux";
import useSocketEvents from "../../hooks/useSocketEvents";
import {
    GAME_STATUS,
    GET_UI_LABELS,
    SOCKET_ACTIONS,
    SOCKET_SEND_EVENTS,
} from "../../helpers/constant";
import SnackBar from "../../helpers/SnackBar";
import ErrorPage from "../ErrorPage/ErrorPage";
import { useNavigate } from "react-router-dom";
import {
    isMobileOrTablet,
    toggleMojoPoints,
    updateMojoPoints,
} from "../../helpers/Global";
import { Language } from "@mui/icons-material";

const Lobby = ({ gameType, uiLabels }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [containerHeight, setContainerHeight] = useState("72vh");
    const [questionsContainerHeight, setQuestionsContainerHeight] =
        useState("100%");

    const playersContainer = useRef();
    const howToContainer = useRef();
    const playersBox = useRef();

    //list of labels to translate
    const [labels, setLabels] = useState(null);

    const search = useLocation().search;
    let token = useSelector(({ token }) => token.token);
    let roomId = useSelector(({ game }) => game.roomId);
    const language = useSelector(({ game }) => game.language);
    const [joinCode, setJoinCode] = useState(null);

    const me = useSelector(({ player }) => player);
    const [oldPlayerId, setOldPlayerId] = useState(null);
    const message = useSelector(({ message }) => message.message);
    const openSnackbar = useSelector(({ message }) => message.openSnackbar);
    const error = useSelector(({ message }) => message.error);
    const gameStatus = useSelector(({ game }) => game.gameStatus);
    const players = useSelector(({ game }) => {
        return game.gamePlayers;
    });

    const socketFromStore = useSelector(({ socket }) => socket.socket);

    const questions = useSelector(({ question }) => question.questions);

    // get all the player details when lobby page is opened
    useEffect(() => {
        const searchParams = new URLSearchParams(search);

        const playerId = searchParams.get("playerId");
        const playerName = searchParams.get("playerName");
        const playerAvatar = searchParams.get("playerAvatar");
        const language_code = searchParams.get("language_code");
        const code = searchParams.get("code");

        //save your profile to state
        dispatch(saveYourProfile(playerId, playerName, playerAvatar));
        setOldPlayerId(searchParams.get("oldPlayerId"));

        const token = searchParams.get("token");
        const loggedIn = searchParams.get("loggedIn");
        if (token !== null) {
            dispatch(addToken(token, loggedIn));
        }

        setJoinCode(code ?? "");
        dispatch(updateRoomId(searchParams.get("roomId")));
        dispatch(updateLanguage(language_code ?? "en"));
    }, [dispatch, search]);

    //establish socket connection
    useSocket(token, language, labels);

    //connect to henrique backend
    const playerId = me.playerId,
        playerName = me.playerName,
        playerAvatar = me.playerAvatar;

    useEffect(() => {
        if (socketFromStore != null && joinCode !== null) {
            setTimeout(() => {
                socketFromStore.emit(SOCKET_SEND_EVENTS.JOIN_ROOM, {
                    playerId,
                    playerName,
                    playerAvatar,
                    roomId,
                    oldPlayerId,
                    joinCode,
                });
            }, 2000);
        }
    }, [socketFromStore, joinCode]);

    const handleMessage = (event) => {
        const payload = event.data;

        if (payload.action) {
            switch (payload.action) {
                case GET_UI_LABELS:
                    const keys = Object.keys(payload.uiLabels ?? []);
                    setLabels(keys);
                    dispatch(updateUILabels(payload.uiLabels));
                    break;
            }
        }
    };

    useEffect(() => {
        toggleMojoPoints(true, true);

        window.addEventListener("message", handleMessage);

        let message = {
            action: GET_UI_LABELS,
        };

        //method to send message to parent outside iframe
        window.top.postMessage(message, "*");

        return () => {
            window.removeEventListener("message", handleMessage);
        };
    }, []);

    useEffect(() => {
        if (!gameType) {
            return;
        }

        if (gameStatus === GAME_STATUS.END) {
            navigate("/end");
        }

        if (
            gameStatus === GAME_STATUS.INGAME &&
            questions &&
            questions.length !== 0
        ) {
            navigate("/play");
        }
    }, [gameStatus, gameType, questions]);

    return (
        /* if no error, lobby page, else errorPage */
        error === "" ? (
            <>
                <Container
                    maxWidth="md"
                    style={{ padding: "0", maxWidth: "750px" }}
                >
                    <Grid
                        container
                        sx={{
                            ml: 0,
                            height: "100%",
                            pt: { xs: "0", md: "2vh" },
                        }}
                        spacing={{ xs: "0" }}
                        className={styles.backgroundColor}
                    >
                        <Box
                            sx={{
                                width: { xs: "100%" },
                                overflow: "hidden",
                                paddingTop: theme.px16.main,
                                paddingBottom: theme.px10.main,
                            }}
                            ref={playersBox}
                        >
                            <Players players={players} />
                        </Box>

                        <LobbySettings uiLabels={uiLabels} />

                        <Box
                            ref={howToContainer}
                            sx={{
                                pt: { sm: ".8rem", md: ".2rem" },
                                width: { xs: "100%" },
                                overflow: "hidden",
                            }}
                        >
                            <HowTo uiLabels={uiLabels} />
                        </Box>
                    </Grid>
                    {message !== "" && (
                        <SnackBar open={openSnackbar} message={message} />
                    )}
                </Container>
            </>
        ) : (
            <ErrorPage />
        )
    );
};

export default Lobby;
